import React, {useEffect} from 'react';
import googleIcon from './../../assets/google_icon.jpg';
import appleIcon from './../../assets/apple_icon.jpg';
import { appStoreURL, playStoreURL } from './../../constants/url';
import ContactUs from './../../components/ContactUs';
import AboutUs from '../../components/AboutUs';
import Vision from '../../components/Vision';
import WhatToDoNow from '../../components/WhatToDoNow';
import PhotosSection from '../../components/PhotosSection';
import PhotosSection2 from '../../components/PhotosSection2';
import SocialMedia from './../../components/SocialMedia';
import {styles} from './Home.component.styles';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { colors } from '../../constants/colors';
import { images } from '../../assets/images';
import '../../lib/i18n';
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t, i18n: {changeLanguage, language} } = useTranslation();
  
  useEffect(() => {
    const browserLocales = navigator.languages || [navigator.language];
    // I need to check if any of the browserLocales is supported by my app and set it as the current language
    // If none of the browserLocales is supported, I need to set the default language
    const supportedLanguages = ['en', 'ar', 'tr'];

    const supportedLanguage = browserLocales.find(locale => supportedLanguages.includes(locale));
    // if navigator.language is supported by my app, I set it as the current language
    if(supportedLanguages.includes(navigator.language)) {
      changeLanguage(navigator.language);
    } else{
      if (supportedLanguage) {
        changeLanguage(supportedLanguage);
      } else {
        changeLanguage('en');
      }
    }
  }, []);

  const handleDownloadClick = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const url = isIOS ? appStoreURL : playStoreURL;
    window.open(url, '_blank');
  };

  return (
    <div style={{width:"100%", padding:0, margin:0}}>
      <div style={{width:"100%", padding: 0,margin:0, backgroundColor:"lightblue"}}>
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: 700,
          backgroundColor: "black",
          opacity: 0.5, // Adjust the opacity as needed
          zIndex: 1
        }}></div>
        <div style={{ position: "absolute", zIndex: 4, top:10, right:"5%", width:150}}>
          <button style={styles.downloadButton} onClick={handleDownloadClick}>
            {t('download')}
          </button>
        </div>
        <div style={{ position: "absolute", zIndex: 2, top:0, width:"100%" }}>
          <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: 'transparent', borderBottomStyle:"solid", borderBottomWidth:2, borderBottomColor:"white" }}>
            <Container >
              <Navbar.Brand href="#home" style={{ color:colors.white, fontWeight:"bold", fontSize:32, fontFamily:"Irish Grover" }}>Eventyol</Navbar.Brand>
            </Container>
          </Navbar>
        </div>
        <PhotosSection images={images} />
        <div style={{ position: "absolute", zIndex: 2, top:300, width:"100%"}}>
          <div style={{width:"100%", textAlign:"Left"}}>
            <p style={{color:"white", fontSize:28, paddingLeft:20, fontFamily:"Irish Grover"}}>
              {t('subTitle')}
            </p>
            <p style={{color:"white", fontSize:12, paddingLeft:20, width:"80%"}}>
              {t('subTitleDescription')}
            </p>
            <div>
              <a href={playStoreURL} target="_blank" rel="noopener noreferrer">
                <img
                  style={{ width: "40%", maxWidth: "150px", margin: 20, borderRadius:5}}
                  src={googleIcon}
                  className="Store-logo"
                  alt="store-logo"
                />
              </a>
              <a href={appStoreURL} target="_blank" rel="noopener noreferrer">
                <img
                  style={{ width: "40%", maxWidth: "150px", margin: 20, borderRadius:5}}
                  src={appleIcon}
                  className="Store-logo"
                  alt="store-logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <WhatToDoNow images={images} />

      <AboutUs />

      <Vision />

      <ContactUs images={images}/>
      
      <PhotosSection2 images={images}/>

      <SocialMedia t={t} changeLanguage={changeLanguage} language={language}/>
    </div>
  );
}


export default Home;