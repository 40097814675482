import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import enJSON from "../locales/en.json";
import trJSON from "../locales/tr.json";
import arJSON from "../locales/ar.json";
import grJSON from "../locales/gr.json";
import esJSON from "../locales/es.json";


i18n.use(initReactI18next).init({
 resources: {
  en: { ...enJSON },
  tr: { ...trJSON },
  ar: { ...arJSON },
  gr: { ...grJSON },
  es: { ...esJSON },
}, // Where we're gonna put translations' files
 lng: "en",     // Set the initial language of the App
});