import React from 'react';
import { Container, Row } from 'react-bootstrap';
import {styles} from './WhatToDoNow.component.style';
import { useTranslation } from "react-i18next";

const WhatToDoNow = ({
  images,
}) => {
  const { t, i18n: {changeLanguage, language} } = useTranslation();

  return(
    <Container fluid>
      <Row>
        <Container fluid style={styles.headerRow}>
          <p style={styles.headerText}>{t('whatToDoNow')}</p>
        </Container>
      </Row>
      <Row>
        <Container fluid style={styles.mainContainer}>
          <Container fluid style={styles.childContainer}>
            <Container>
              <p style={styles.itemHeader}>{t('downloadTheApp')}</p>
              <p style={styles.itemText}>{t('downloadAppText')}</p>
            </Container>
            <Container style={{ textAlign:"center"}}>
              <img src={images.homePageImage} style={{width:"80%", height:"100%", maxWidth:400}}/>
            </Container>
          </Container>
          <Container fluid style={styles.childContainer}>
            <Container>
              <p style={styles.itemHeader}>{t('explore')}</p>
              <p style={styles.itemText}>{t('exploreText')}</p>
            </Container>
            <Container style={{ textAlign:"center"}}>
              <img src={images.mapPageImage} style={{width:"80%", height:"100%", maxWidth:400 }}/>
            </Container>
          </Container>
          <Container fluid style={styles.childContainer}>
          < Container>
              <p style={styles.itemHeader}>{t('haveFun')}</p>
              <p style={styles.itemText}>{t('haveFunText')}</p>
            </Container>
            <Container style={{ textAlign:"center"}}>
              <img src={images.eventPageImage} style={{width:"80%", height:"100%", maxWidth:400}}/>
            </Container>
          </Container>
        </Container>
      </Row>
    </Container>
  )
}


export default WhatToDoNow;