import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { logoutAdmin, clearState } from '../../redux/features/admin/adminSlice';
import { clearEvents } from "../../redux/features/event/eventSlice";
import { clearUsers } from "../../redux/features/user/userSlice";
import { persistor } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/colors';

const AdminNavbar = ({setSelectedItem}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const user = useSelector((state) => state.admin.user);

  const handleLogout = async () => {
    dispatch(logoutAdmin());
    dispatch(clearState());
    dispatch(clearEvents());
    dispatch(clearUsers());
    await persistor.purge();
    navigate('/login');
  };

  return (
    <React.Fragment>
      <Navbar expand="lg" style={{backgroundColor: colors.dark_blue }}>
        <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Navbar.Brand style={{color:"white", fontWeight:"bold"}}>Hi {user.first_name} - {user.city.name}</Navbar.Brand>
          <Navbar.Brand style={{color:"white", fontWeight:"bold"}} onClick={() => setSelectedItem('Events')}>Events</Navbar.Brand>
          <Navbar.Brand style={{color:"white", fontWeight:"bold"}} onClick={() => setSelectedItem('Hosts')}>Hosts</Navbar.Brand>
          <Navbar.Brand style={{color:"white", fontWeight:"bold"}} onClick={() => setSelectedItem('Locations')}>Locations</Navbar.Brand>
          <Navbar.Brand style={{color:"white", fontWeight:"bold"}} onClick={() => setSelectedItem('Users')}>Users</Navbar.Brand>
          <Navbar.Brand style={{color:"white", fontWeight:"bold"}} onClick={() => setSelectedItem('Recomendations')}>Recomendations</Navbar.Brand>
          <Navbar.Brand style={{color:"white", fontWeight:"bold"}} onClick={() => setSelectedItem('Messages')}>Messages</Navbar.Brand>
          <Nav className="ml-auto">
            <button onClick={handleLogout} className="btn btn-warning" style={{ marginLeft: 'auto' }}>
              Logout
            </button>
          </Nav>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

export default AdminNavbar;