import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosInstance';

// fetch all locations
export const fetchLocations = createAsyncThunk(
  'admin/fetchlocations',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.get('/admin/locations', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// create location
export const createLocation = createAsyncThunk(
  'admin/createlocation',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.post('/admin/locations', requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update location
export const editLocation = createAsyncThunk(
  'admin/editlocation',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.put(`/admin/locations/${requestBody.id}`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  locations: [],
  status: 'idle',
  error: null
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    clearlocations: (state) => {
      state.locations = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocations.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLocations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.locations = action.payload;
      })
      .addCase(fetchLocations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createLocation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.locations.push(action.payload);
      })
      .addCase(createLocation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(editLocation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editLocation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.locations = state.locations.map((location) =>
          location.id === action.payload.id ? action.payload : location
        );
      })
      .addCase(editLocation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export const { clearMesssages } = locationSlice.actions;

export default locationSlice.reducer;
