import { changeLanguage } from 'i18next';
import { colors } from './../../constants/colors';

export const styles = {
  headerText:{
    color: colors.white,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: colors.black,
    fontFamily:"Irish Grover"
  },
  mainContainer:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    backgroundColor: colors.black,
  },
  copyRightRow:{
    backgroundColor: colors.black,
    paddingTop: 15,
    width: '100%',
    textAlign: 'center',
    minHeight: 60,
  },
  copyRightText:{
    color: colors.white,
    fontSize: 12,
    textAlign: 'center',
  },
  changeLanguageRow: {
    textAlign: 'center',
    backgroundColor: colors.black,
  },
  selectButton: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    backgroundColor: colors.dark_blue,
    color: colors.white,
    cursor: 'pointer',
    outline: 'none',
    transition: 'background-color 0.3s ease',
  },
}