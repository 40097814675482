import React, {useState} from 'react';
import { Container, Row } from 'react-bootstrap';
import {styles} from './ContactUs.component.style';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { emailValidator } from '../../utils/validations';
import { colors } from '../../constants/colors';
import { useTranslation } from "react-i18next";

const ContactUs = ({images}) => {
  const [emailAddress, setEmailAddress] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [description, setDescription] = useState("")
  const { t, i18n: {changeLanguage, language} } = useTranslation();

  const onContactUsFormClicked = () => {
    // check if email is valid
    if(!emailValidator(emailAddress)){
      toast("Please enter a valid email address!",{
        position: "top-center",
        type: "error"
      });
      return;
    }
    // check if all fields are filled
    if(emailAddress === "" || firstName === "" || lastName === "" || description === ""){
      toast("Please fill all the fields!",{
        position: "top-center",
        type: "error"
      });
      return;
    }
    const apiUrl = process.env.REACT_APP_API_URL;
    fetch(`${apiUrl}/contact_us`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: emailAddress,
        first_name: firstName,
        last_name: lastName,
        description: description
      }),
    })
    .then(response => response.json())
    .then(data => {
      toast("We have received your message and will contact you within 72 hours. Thank you!",{
        position: "top-center",
        type: "success"
        });
    })
    .catch((error) => {
      console.error('Error:', error);
      toast("Something happened! Please try again!",{
        position: "top-center",
        type: "error"
        });
    });
    // clear the form
    setEmailAddress("")
    setFirstName("")
    setLastName("")
    setDescription("")
  }

  return(
    <Container fluid style={styles.mainContainer}>
       <ToastContainer />
      <Container fluid style={styles.childContainer}>
        <Container fluid>
          <Row style={styles.titleRow}>
            <text style={styles.title}>{t('contactUs')}</text>
          </Row>
        </Container>
        <Container fluid style={styles.container}>
          <Row style={styles.headerRow}>
            <text>{t('contactUsText')}</text>
          </Row>
          <Row style={styles.inputRow}>
              <text style={styles.inputTitle}>{t('emailAddress')}</text>
              <input style={styles.input} type="text" value={emailAddress} onChange={(e)=>{setEmailAddress(e.target.value)}} required/>
          </Row>
          <Row style={styles.inputRow}>
                <text style={styles.inputTitle}>{t('firstName')}</text>
                <input style={styles.input} type="text" value={firstName} onChange={(e)=>{setFirstName(e.target.value)}}/>
          </Row> 
          <Row style={styles.inputRow}>
              <text style={styles.inputTitle}>{t('lastName')}</text>
              <input style={styles.input} type="text" value={lastName} onChange={(e)=>{setLastName(e.target.value)}}/>
          </Row>
          <Row style={styles.inputRow}>
              <text style={styles.inputTitle}>{t('howWeCanHelp')}</text>
              <textarea style={styles.input} type="text" multiline rows={4} placeholder={t('pleaseDescribe')} value={description} onChange={(e)=>{setDescription(e.target.value)}}/>
          </Row> 
        </Container>
        <Container fluid>
          <Row>
            <button style={styles.button} onClick={onContactUsFormClicked}>{t('submit')}</button>
          </Row>
        </Container>
      </Container>
      <Container fluid style={styles.childContainer}>
        <Container fluid style={{...styles.container, marginTop:30, padding:0, borderStyle:"none", backgroundColor:colors.white, textAlign:"center"}}>
          <img src={images.contactPersonImage} alt="soccer" style={styles.contactPersonImageStyle}/>
          {/* <PhotosSection2 images={images}/> */}
        </Container>
      </Container>
    </Container>
  )
}


export default ContactUs;