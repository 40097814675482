import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Row, Col, Form } from 'react-bootstrap';
import './../../styles/styles.css';
import { useSelector, useDispatch  } from 'react-redux';
import { createLocation } from '../../redux/features/location/locationSlice';
import { validateLocationRequiredFields } from '../../utils/validations';

function CreateLocationModal({
  showCreateLocationModal,
  setShowCreateLocationModal,
}) {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.admin.cities);
  const country_id = cities[0].country_id;

  const [location_name, setLocationName] = useState("");
  const [city_id, setCityId] = useState(cities ? cities[0].id : "");
  const [location_type, setLocationType] = useState("");
  const [location_address, setLocationAddress] = useState("");
  const [location_city, setLocationCity] = useState("");
  const [location_state, setLocationState] = useState("");
  const [location_zip, setLocationZip] = useState("");
  const [location_phone, setLocationPhone] = useState("");
  const [location_email, setLocationEmail] = useState("");
  const [location_url, setLocationUrl] = useState("");
  const [contact_person, setContactPerson] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");

  const cityOptions = cities.map((city) => (
    <option key={city.id} value={city.id}>
      {city.name + ", " + city.state}
    </option>
  ));

  const clearForm = () => {
    // clear the form
    setLocationName("");
    setLocationType("");
    setLocationAddress("");
    setLocationCity("");
    setLocationState("");
    setLocationZip("");
    setLocationPhone("");
    setLocationEmail("");
    setLocationUrl("");
    setContactPerson("");
    setLat("");
    setLong("");
  };


  const handleClose = () => {
    clearForm();
    setShowCreateLocationModal(false)
  };

  const handleCreateLocation = () => {

    const requestBody = {
      location_name: location_name,
      city_id: city_id,
      country_id: +country_id,
      location_type: location_type,
      location_address: location_address,
      location_city: location_city,
      location_state: location_state,
      location_zip: +location_zip,
      location_phone: location_phone,
      location_email: location_email,
      location_url: location_url,
      contact_person: contact_person,
      lat: +lat,
      long: +long
    };

    if (!validateLocationRequiredFields(requestBody)) {
      return;
    }

    dispatch(createLocation(requestBody));
    clearForm();
    setShowCreateLocationModal(false);

  };


  return (
    <>
      <Modal show={showCreateLocationModal} fullscreen={true} >
        <Modal.Header>
          <Modal.Title>Create Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row style={{marginBottom:20}}>
              <Col xs={21} md={6}>
                <Form.Label htmlFor="inputPassword5">Location Name</Form.Label>
                <Form.Control
                  type="text"
                  value={location_name}
                  placeholder='Enter an Location Name'
                  onChange={(e) => setLocationName(e.target.value)}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">Location Type</Form.Label>
                <Form.Control
                  type="text"
                  value={location_type}
                  placeholder='Enter an Location Type'
                  onChange={(e) => setLocationType(e.target.value)}
                />
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={12} md={12}>
                <Form.Label htmlFor="inputPassword5">Location Address</Form.Label>
                <Form.Control
                  type="text"
                  value={location_address}
                  placeholder='Enter an Location Address'
                  onChange={(e) => setLocationAddress(e.target.value)}
                />
              </Col>  
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">Location City</Form.Label>
                <Form.Control
                  type="text"
                  value={location_city}
                  placeholder='Enter an Location City'
                  onChange={(e) => setLocationCity(e.target.value)}
                />
              </Col>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">Location State</Form.Label>
                <Form.Control
                  type="text"           
                  value={location_state}
                  placeholder='Enter an Location State'
                  onChange={(e) => setLocationState(e.target.value)}
                />
              </Col>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">Zipcode</Form.Label>
                <Form.Control
                  type="numeric"
                  value={location_zip}
                  placeholder='Enter an Zipcode'
                  onChange={(e) => setLocationZip(e.target.value)}
                />
              </Col>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">Area</Form.Label>
                <Form.Select 
                  aria-label="Default select example"
                  value={city_id}
                  onChange={(e) => setCityId(e.target.value)}
                >
                  {cityOptions}
                </Form.Select>
              </Col>
            </Row>

            <Row style={{marginBottom:20}}>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">Phone</Form.Label>
                <Form.Control
                  type="text"
                  value={location_phone}
                  placeholder='Enter an Phone'
                  onChange={(e) => setLocationPhone(e.target.value)}
                />
              </Col>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">Email</Form.Label>
                <Form.Control
                  type="text"
                  value={location_email}
                  placeholder='Enter an Email'
                  onChange={(e) => setLocationEmail(e.target.value)}
                />
              </Col>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">URL</Form.Label>
                <Form.Control
                  type="text"
                  value={location_url
                  }
                  placeholder='Enter an URL'
                  onChange={(e) => setLocationUrl(e.target.value)}
                />
              </Col>
              <Col xs={6} md={3}>
                <Form.Label htmlFor="inputPassword5">Contact Person</Form.Label>
                <Form.Control
                  type="text"
                  value={contact_person}
                  placeholder='Enter an Contact Person'
                  onChange={(e) => setContactPerson(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">Latitude</Form.Label>
                <Form.Control
                  type="number"
                  value={lat}
                  placeholder='Enter an Latitude'
                  onChange={(e) => setLat(e.target.value)}
                />
              </Col>
              <Col xs={12} md={6}>
                <Form.Label htmlFor="inputPassword5">Longitude</Form.Label>
                <Form.Control
                  type="number"
                  value={long}
                  placeholder='Enter an Longitude'
                  onChange={(e) => setLong(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCreateLocation}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateLocationModal;