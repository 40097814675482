import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosInstance';

// fetch all events
export const fetchEvents = createAsyncThunk(
  'admin/fetchEvents',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.get('/admin/games', {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// create event
export const createEvent = createAsyncThunk(
  'admin/createEvent',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.post('/admin/games', requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// update event
export const updateEvent = createAsyncThunk(
  'admin/updateEvent',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.put(`/admin/games/${requestBody.id}`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// delete event
export const deleteEvent = createAsyncThunk(
  'admin/deleteEvent',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.delete(`/admin/games/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// add user to event
export const addUserToEvent = createAsyncThunk(
  'admin/addUserToEvent',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.post(`/admin/games/${requestBody.event_id}/add_user`, requestBody, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// remove user from event
export const removeUserFromEvent = createAsyncThunk(
  'admin/removeUserFromEvent',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.delete(`/admin/games/${requestBody.id}/remove_user/${requestBody.user_id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// add image to event
export const addImageToEvent = createAsyncThunk(
  'admin/addImageToEvent',
  async (formData, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.post(`/admin/games/${formData.get('id')}/add_image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

// delete image from event
export const deleteImageFromEvent = createAsyncThunk(
  'admin/deleteImageFromEvent',
  async (requestBody, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.admin.authToken; // Adjust according to your state structure

      const response = await axiosInstance.delete(`/admin/games/${requestBody.id}/remove_image/${requestBody.image_id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      return response.data;
    } catch (error) {
      // TO DO: Handle error properly
      alert(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  events: [],
  status: 'idle',
  error: null
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    clearEvents: () => {
      return initialState; // Reset state to initial values
    },
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(createEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.events.push(action.payload);
        state.status = 'succeeded';
      })
      .addCase(createEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        );
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = state.events.filter((event) => event.id !== action.payload.game_id);
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addUserToEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addUserToEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        );
      })
      .addCase(addUserToEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(removeUserFromEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(removeUserFromEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        );
      })
      .addCase(removeUserFromEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(addImageToEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addImageToEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        );
      })
      .addCase(addImageToEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteImageFromEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteImageFromEvent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.events = state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        );
      })
      .addCase(deleteImageFromEvent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export const { clearError, clearEvents } = eventSlice.actions;

export default eventSlice.reducer;
