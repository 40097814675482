import { colors } from '../../constants/colors';
export const styles = {
  mainContainer:{
    paddingTop: 60,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'space-between',
    margin: 0,
    paddingBottom: 50,
    backgroundColor: colors.white,
    width: '100%',
  },
  childContainer :{
    width: '50%',
    minWidth: 300,
    flex:1,
  },
  button: {
    backgroundColor: colors.white, // Blue background
    color: colors.dark_blue, // Text color
    height: 60,
    marginTop: -30,
    borderRadius: 10,
    borderStyle: "none",
    width: '60%',
    marginLeft: '20%',
    fontSize: 25,
    fontWeight: 'bolder',
    fontFamily:"Irish Grover"
  },
  headerRow: {
    width: '90%',
    marginLeft: '5%',
    color: colors.white,
    fontSize: 12,
    fontStyle: 'italic',
    marginBottom: 30,
  },
  inputRow: {
    justifyContent:"center",
    width: '90%',
    marginLeft: '5%',
    fontSize: 12,
  },
  inputTitle: {
    width: '100%',
    minHeight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    color: colors.white,
  },
  input: {
    width: '100%',
    minHeight: 40,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 5,
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
  },
  headerText: {
      textAlign: 'center',
      color: '#3c84c4',
      fontSize: 25,
      fontStyle: 'italic',
      fontWeight: 'bolder',
  },
  title:{
    color: colors.dark_blue,
    fontSize: 30,
    fontWeight: 'bold',
    fontFamily:"Irish Grover"
  },
  titleRow:{
    justifyContent:"center",
    height: 60,
    paddingTop: 10,
    textAlign: 'center',
    backgroundColor: colors.white,
    width: '70%',
    marginLeft: '15%',
    marginBottom: -30,
    borderRadius: 10,
    zIndex: 10,
    position: 'relative', // Ensure it is positioned correctly
  },
  container: {
    backgroundColor: colors.dark_blue,
    paddingTop: 60,
    paddingBottom: 30,
    height:550,
    width: '100%',
  },
  contactPersonImageStyle:{
    maxHeight:550,
    maxWidth: "100%",
  }
}