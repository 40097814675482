import React from 'react';
import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons/meetup'
import 'react-social-icons/x'
import 'react-social-icons/facebook'
import 'react-social-icons/instagram'
import 'react-social-icons/youtube'
import { styles }  from './SocialMedia.component.style';

const SocialMedia = ({t, changeLanguage, language}) => {

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };

  return (
    <div>
      <div style={styles.mainContainer}>
        <text style={styles.headerText}>{t('followUsOn')}</text>
        <SocialIcon url="https://www.facebook.com/profile.php?id=61567805852891" style={{ height: 30, width: 30, margin:15 }} />
        <SocialIcon url="https://www.instagram.com/eventyolthebayarea/" style={{ height: 30, width: 30, margin:15 }} />
        <SocialIcon url="https://x.com/eventyol197301" style={{ height: 30, width: 30, margin:15 }} />
        <SocialIcon url="https://www.youtube.com/@eventyol" style={{ height: 30, width: 30, margin:15  }} />
      </div>
      <div style={styles.copyRightRow}>
        <text style={styles.copyRightText}>{t('hotpotAI')}</text>
      </div>
      <div style={styles.changeLanguageRow}>
        <select
          value={language}
          onChange={handleLanguageChange}
          style={{
            ...styles.selectButton,
          }}
        >
          <option value="ar">Arabic</option>
          <option value="gr">German</option>
          <option value="es">Spanish</option>
          <option value="en">English</option>
          <option value="tr">Turkish</option>
        </select>
      </div>
      <div style={styles.copyRightRow}>
        <text style={styles.copyRightText}>{t('copyRight')}</text>
      </div>
    </div>

  )
}

export default SocialMedia;
