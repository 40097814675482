import React from 'react';
import { colors } from '../../constants/colors';
import { useTranslation } from "react-i18next";
const campingRecImage2 = require('./../../assets/camping_rec_2.jpg');

const Vision = () => {
  const { t, i18n: {changeLanguage, language} } = useTranslation();

  return(
    <div style={{
      position: "relative",
      color: "white",
      textAlign: "left",
      fontSize: 30,
      fontWeight: "bold",
      paddingBottom: "5%",
      backgroundColor: colors.dark_blue,
    }}>
      <div style={{ position: "relative", zIndex: 2,paddingTop:"2%", paddingBottom:"3%" }}>
        <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.button_orange, textAlign:"center", fontFamily:"Irish Grover"}}>
          {t('vissionTitle')}
        </p>
        <div>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%", textAlign:"center"}}>
            {t('vissionText')}
          </p>
        </div>
        <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.button_orange, textAlign:"center", fontFamily:"Irish Grover"}}>
          {t('missionTitle')}
        </p>
        <div>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%", textAlign:"center"}}>
            {t('missionText')}
          </p>
        </div>
        <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.button_orange, textAlign:"center", fontFamily:"Irish Grover"}}>
          {t('valuesTitle')}
        </p>
        <div>
          <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.icon_green, fontFamily:"Irish Grover"}}>
            {t('community')}
          </p>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
            {t('communityText')}
          </p>
          <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.icon_green, fontFamily:"Irish Grover"}}>
            {t('fun')}
          </p>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
            {t('funText')} 
          </p>
          <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.icon_green, fontFamily:"Irish Grover"}}>
            {t('accessibility')}
          </p>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
            {t('accessibilityText')}
          </p>
          <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.icon_green, fontFamily:"Irish Grover"}}>
            {t('innovation')}
          </p>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
            {t('innovationText')}
          </p>
          <p style={{fontSize:20, paddingLeft:"10%", paddingRight:"10%", color:colors.icon_green, fontFamily:"Irish Grover"}}>
            {t('safety')}
          </p>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
            {t('safetyText')}
          </p>
        </div>
      </div>
    </div>   
  )
}


export default Vision;