import React from 'react';
import { useTranslation } from "react-i18next";
const campingRecImage2 = require('./../../assets/camping_rec_2.jpg');

const AboutUs = () => {
  const { t, i18n: {changeLanguage, language} } = useTranslation();

  return(
    <div style={{
      position: "relative",
      height: "100vh",
      color: "white",
      textAlign: "center",
      fontSize: 30,
      fontWeight: "bold",
      padding: 20,
      paddingTop: "5%",
      paddingBottom: "5%",
      backgroundImage: `url(${campingRecImage2})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat"
    }}>
      <div style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        opacity: 0.5, // Adjust the opacity as needed
        zIndex: 1
      }}></div>
      <div style={{ position: "relative", zIndex: 2, paddingTop:"10%" }}>
        <p style={{fontFamily:"Irish Grover"}}>
          {t('aboutUs')}
        </p>
        <div>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
            {t('aboutUsText1')}
          </p>
          <p style={{fontSize:15, paddingLeft:"10%", paddingRight:"10%"}}>
          {t('aboutUsText2')}
          </p>
        </div>
      </div>
    </div>   
  )
}


export default AboutUs;